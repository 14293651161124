require('../polyfill/Polyfill');
import MicroModal from "micromodal";

const modalElem = document.getElementById("modal");
const triggerElem = document.getElementById("modal-trigger");
const contentElem = document.getElementById("c-modal__content");

const onClose = () => {
  contentElem.innerHTML = "";
};

MicroModal.init({
  onClose,
  disableScroll: true,
  awaitCloseAnimation: true,
  debugMode: true,
});

export const setContent = (content) => {
  contentElem.innerHTML = content;
  triggerElem.click();
};
