const $gnav = $('#gnav');
const $gnavBtn = $('#gnav-btn');
const $gnavBg = $('#gnav-bg')
let close = false;  // gnavボタンがcloseかどうか
let st = 0; // スクロール位置

export const Common = {
  // ************************************
    // gnavを表示する & 非表示にする
    // ************************************
  gnav : () => {
    // gnavボタン押下時にgnavの表示・非表示を切り替える
    $gnavBtn.on('click', function() {
      if (close !== true) {
        // スクロールを禁止する
        st = $(window).scrollTop();
        $('body').addClass('js-fixed').css({'top': -st});
        close = true;
        // gnavを表示
        $gnav.addClass('js-gnav-show');
        $gnavBg.addClass('js-gnav-bg-show');
        // ボタンを閉じるボタンにする
        $gnavBtn.addClass('js-close-btn');
      }
      else {
        // gnav非表示
        $gnav.removeClass('js-gnav-show');
        $gnavBg.removeClass('js-gnav-bg-show');
        // gnavボタンを通常に戻す
        $gnavBtn.removeClass('js-close-btn');
        close = false;
        // スクロールを許可する
        $('body').removeClass('js-fixed').css({'top': 0});
        window.scrollTo(0, st);
      }
    });

    $('.gnav__link').on('click', function() {
        // gnav非表示
        $gnav.removeClass('js-gnav-show');
        $gnavBg.removeClass('js-gnav-bg-show');
        // gnavボタンを通常に戻す
        $gnavBtn.removeClass('js-close-btn');
        close = false;
        // スクロールを許可する
        $('body').removeClass('js-fixed').css({'top': 0});
        window.scrollTo(0, st);
    });
  },

  // ************************************
  // スムーススクロール
  // ************************************
  smoothscroll : () => {
    $('a[href^="#"]:not([href="#"])').on('click', function() {
      let target = 0;
      if ($(this).attr('href') !== "##") {
        target = $($(this).attr('href')).offset().top;
        target = target;
      }
      $('html, body').animate({scrollTop: target}, 700);
      return false;
    });
  },

  // ************************************
  // リサイズ時の調整
  // ************************************
  resize : () => {
    $(window).resize(function() {
      // PC版の幅のとき
      if ($(window).width() > 768) {
        // sp版のgnav表示中の場合は非表示にする（スクロールを可能とする）
        if ($gnav.hasClass('js-gnav-show')) {
          // gnav非表示
          $gnav.removeClass('js-gnav-show');
          $gnavBg.removeClass('js-gnav-bg-show');
          // gnavボタンを通常に戻す
          $gnavBtn.removeClass('js-close-btn');
          close = false;
          // スクロールを許可する
          $('body').removeClass('js-fixed').css({'top': 0});
          window.scrollTo(0, st);
        }
      }
    });
  },


}
