export default class {

  // ------------------------------------------
  // コンストラクタ
  // ------------------------------------------
  constructor(selectedContainer, visibleList, categoryTtl) {
    this.selectedContainer = document.querySelector(selectedContainer); // 選択されたアイテムを表示する領域
    this.visibleList = document.querySelector(visibleList); // 選択可能なアイテムをユーザーに選択させるための領域
    this.categoryTtl = document.querySelector(categoryTtl); // カテゴリー名

    // this.activedCategory = "selected-category";

    //セットデータ（ローカルストレージ内のデータ）
    // this.setDataToSelectedItem();

    // リスト表示イベント登録
    this.selectedContainer.addEventListener('click', (e) => {
      this.show();
      e.stopPropagation();
    });

    // リスト非表示イベント登録（リスト外領域）
    window.addEventListener('click', () => {
      this.close();
    });

    // リスト選択イベント登録
    // this.cateLink = this.visibleList.getElementsByTagName("a");

    // for (let i = 0; i < this.cateLink.length; i++) {

    //   this.cateLink[i].addEventListener('click', (e) => {
    //     this.clickListItem(e.target);
    //   });

    // }

  }

  // *********** 以下、ローカル関数 ********************

    // ------------------------------------------
  // 選択可能なリストを表示する
  // ------------------------------------------
  show() {
    this.visibleList.style.display = 'block';
  }

  // ------------------------------------------
  // 選択可能なリストを閉じる
  // ------------------------------------------
  close() {
    this.visibleList.style.display = 'none';
  }

  // ------------------------------------------
  // リスト上のアイテムをクリックしたときの処理
  // ------------------------------------------
  clickListItem(clickedItem) {

    let txt = clickedItem.innerHTML;
    this.setLocalStrage(txt, this.selectedContainer);

  }


  // ------------------------------------------
  // 選択した値をローカルストレージに格納
  // ------------------------------------------
  setLocalStrage(selectedTxt, categoryName){

    localStorage.setItem('key', selectedTxt);
    const data = localStorage.getItem('key');
    categoryName.innerHTML = data;

  }

  // ------------------------------------------
  // ローカルストレージのデータと紐付いている文字列にクラス付与
  // ------------------------------------------
  setDataToSelectedItem(){
    window.addEventListener('DOMContentLoaded', () => {
      const keyName = "key";
      const value = localStorage.getItem(keyName);
      this.selectedContainer.innerHTML = value;

      const categoryList  = this.visibleList.querySelectorAll('li');

      for (let i = 0; i < categoryList.length; i++) {

        let pageCategoryText = categoryList[i].textContent.trim();
        switch (pageCategoryText) {
          case value:

            if(this.categoryTtl !== null){
              categoryList[i].classList.add(this.activedCategory);
            }else{
              return;
            }

            break;
          default:
        }

      }

    });
  }

}
