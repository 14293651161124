// 各施設サイト用JS

import 'picturefill';
import "scroll-behavior-polyfill";
import { Common } from './_include/Common'
import { setContent } from "./class/Modal"


// ************************************
// gnavを表示する & 非表示にする
// ************************************
Common.gnav();
// ************************************
// スムーススクロール
// ************************************
Common.smoothscroll();
// ************************************
// リサイズ時の調整
// ************************************
Common.resize();

// ************************************
// お知らせ一覧ページのカテゴリ選択
// ************************************
import TextList from './class/TextList';
// フォームの処理
if ($('main').hasClass('page-information') === true) {
  const select = new TextList('#selected-item', '#visible-list', '.category-ttl');
}

// ************************************
// フロアマップmodal
// ************************************
const floormap = (() => {
  const itemElems = document.querySelectorAll(".taiyo-floormap-modal-trigger");
  // IE11を判定する.

  // UserAgent を取得し、全て小文字にする.
  var ua = window.navigator.userAgent.toLowerCase();

  // Internet Explorer であるかを判定する.
  var isIE = (ua.indexOf('msie') >= 0 || ua.indexOf('trident') >= 0);

  // もしIEの場合は、
  if (isIE) {

    // IEのバージョンを、正規表現で取得する.
    var array = /(msie|rv:?)\s?([\d\.]+)/.exec(ua);
    var version = (array) ? array[2] : '';

    // バージョンを整数の形式にする（11.0 -> 11）
    version = version.split('.')[0];

    // IE11であるかを判定する.
    if (version === '11') {
      // IE11です！
      const itemElemsArr = Array.prototype.slice.call(itemElems); //Array
      itemElemsArr.forEach((elem) => {
        elem.addEventListener("click", () => {
          console.log("test")
          if(elem.querySelector(".taiyo-floormap-modal")) {
            setContent(elem.querySelector(".taiyo-floormap-modal").innerHTML);
          }
        });
      });

    }
  } else {
    itemElems.forEach((elem) => {
      elem.addEventListener("click", () => {
        console.log("test")
        if(elem.querySelector(".taiyo-floormap-modal")) {
          setContent(elem.querySelector(".taiyo-floormap-modal").innerHTML);
        }
      });
    });
  }
})();

// ************************************
// お問い合わせページのリセットボタン押下処理
// ************************************
$('.page-contact #reset-btn').on('click', function() {
  let el = document.querySelectorAll('.js-textbox');
  for (let i = 0; i < el.length; i++) {
    el[i].value = '';
  }
  // checkbox
  el = document.querySelectorAll('.js-checkbox');
  for (let i = 0; i < el.length; i++) {
    el[i].checked = false;
  }
});

import Contact from './class/Contact';
new Contact();

// ************************************
// PC版ヘッダーホバー時小リンク表示
// ************************************
$('.js-parent-link').hover(
  function() {
    $(this).children('.js-child-link').fadeIn(100);
  },
  function() {
    $(this).children('.js-child-link').fadeOut(100);
  }
);

// ************************************
// よくある質問ページ
// ************************************
if (($('main').hasClass('page-faq') === true)) {
  $('.js-qa-q').on('click', function() {
    $(this).siblings('.js-qa-a').stop().slideToggle(200);
    $(this).toggleClass('js-opened-qa');
  });
}

// ************************************
// ハッシュ付きページ表示
// ************************************
window.addEventListener('load', function (e) {
  if (document.body.clientWidth <= 768) {
    // 固定ヘッダーの高さ分、位置の調整
    const urlHash = location.hash;
    // URLにアンカーが存在する場合
    if(urlHash){
      // 既定処理を実行しない
      e.preventDefault();
      // 位置を調整する
      $('body,html').stop().scrollTop(0);
      let target = $(urlHash).offset().top;
      if (document.querySelector('.sp-header')) {
        target = target - $('.sp-header').outerHeight();
      }
    $(window).scrollTop(target);
    }
  }
});
