export default class {

  // ------------------------------------------
  // コンストラクタ
  // ------------------------------------------
  constructor() {
    this.submitCheckLabel = document.querySelector('.form__check-container label')
    this.submitCheck = document.querySelector('.form__check-container input')
    this.submitButton = document.querySelector('.form__submit')



    if(this.submitCheckLabel) {
      this.submitCheckControll()

    }



  }

  //同意確認
  submitCheckControll() {

    window.addEventListener('pageshow', ()=> {

      if(document.URL.match('confirm')){
        $('[name="agree"]').prop('checked',true);
        this.submitButton.classList.remove('button__disabled');
      }else{
        $('[name="agree"]').prop('checked',false);
      }

    })
    this.submitCheckLabel.addEventListener('click', () => {
        if(this.submitCheck.checked) {
            this.submitButton.classList.add('button__disabled')
        } else {
            this.submitButton.classList.remove('button__disabled')
        }
    })
}


}
